﻿// ********************************************************************
// ***                                                              ***
// ***            DO NOT MODIFY THIS FILE MANUALLY!                 ***
// *** This file was generated by the API2ANGULAR Service Generator ***
// ***                                                              ***
// ********************************************************************

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpParameterCodec } from '@angular/common/http';
import { environment } from '../../environments/environment';


export class ProsjektDto {
    public id: number;
    public text: string;
}

export class RapportDto {
    public Success: boolean;
    public ResultCode: number;
    public Rapport: OppforingerDto[] = [];
    public Bymiljøetaten: boolean;
    public ArbeidsflytBim2shareNo: boolean;
    public VisOppfølgingsKolonner: VisOppfølgingsKolonnerDto;
}

export class OppforingerDto {
    public Innsendt: string;
    public Hovedkontrakt: string;
    public Oppforingsnr: string;
    public Oppforingstype: string;
    public Tittel: string;
    public KravBelop: number;
    public AntattBelop: number;
    public FakturertBelop: number;
    public SisteMeldingSendt: string;
    public LiggerHos: string;
    public Hovedstatus: string;
    public LokalStatus: string;
    public Merknader: string;
    public OmforentUavklartBelop: number;
    public OmforentBelop: number;
    public OpprinneligKravBelop: number;
    public FrafaltBelop: number;
    public UtgarFraKontraktBelop: number;
    public ReellMerkostnadBelop: number;
    public AvklartOkonomiOgTidBelop: number;
    public EtatEier: string;
    public OmtvistetBelop: number;
    public Slettet: string;
    public SlettetAv: string;
    public FakturertDato: string;
    public AndreEnnBH: string;
    public FerdigFakturert: string;
    public UE: string;
    public ReferanseFraBH: string;
    public ReferanseUE: string;
    public Tilvalg: string;
    public GodkjentBelop: number;
    public ForventetGodkjentBelop: number;
    public GjenstaendeKostnad: number;
    public FakturertGodkjent: number;
    public KrevetFristforlengelse: number;
    public OmforentFristforlengelse: number;
}

export class VisOppfølgingsKolonnerDto {
    public VisKravBelop: boolean;
    public VisAntattBelop: boolean;
    public VisFakturertBelop: boolean;
    public VisFakturertDato: boolean;
    public VisAndreEnnBH: boolean;
    public VisFerdigFakturert: boolean;
    public VisUE: boolean;
    public VisReferanseFraBH: boolean;
    public VisReferanseUE: boolean;
    public VisTilvalg: boolean;
    public VisGodkjentBelop: boolean;
    public VisOmtvistetBelop: boolean;
    public VisForventetGodkjentBelop: boolean;
    public VisGjenstaendeKostnad: boolean;
    public VisFakturertGodkjent: boolean;
    public VisEtatEier: boolean;
    public VisAvklart: boolean;
    public VisFrafalt: boolean;
    public VisOmforent: boolean;
    public VisOmforentUavklart: boolean;
    public VisOpprinneligKrav: boolean;
    public VisReellMerkostnad: boolean;
    public VisUtgarFraKontrakt: boolean;
    public VisKrevetFristforlengelse: boolean;
    public VisOmforentFristforlengelse: boolean;
}

export class WorkingEncoder implements HttpParameterCodec {
    encodeKey(key: string): string { return encodeURIComponent(key); }
    encodeValue(value: string): string { return encodeURIComponent(value); }
    decodeKey(key: string): string { return decodeURIComponent(key); }
    decodeValue(value: string): string { return decodeURIComponent(value); }
}

@Injectable({
   providedIn: "root"
})
export class RapportOppforingerService {
    private serviceUrl = environment.APIUrl + 'RapportOppforinger/';

    constructor(private http: HttpClient) { } 

    private addArray(params: HttpParams, key: string, value: any): HttpParams {
        if (typeof value === "undefined" || value === null) return params;
        if (Array.isArray(value) && value.length === 0) return params;
        for (let i = 0; i < value.length; i++) params = params.append(key + "[]", value[i].toString());
        return params;
    }

    public GetProsjekter(): Promise<ProsjektDto[]> {
        return <Promise<ProsjektDto[]>>this.http.get(this.serviceUrl + "GetProsjekter", { withCredentials: true }).toPromise();
    }

    public GetRapport(prosjektId: number): Promise<RapportDto> {
        let params = new HttpParams({ encoder: new WorkingEncoder() })
        params = params.set("prosjektId",prosjektId != null ? prosjektId.toString() : null);
        return <Promise<RapportDto>>this.http.get(this.serviceUrl + "GetRapport", { params, withCredentials: true }).toPromise();
    }

    public GetRapportXlsx(prosjektId: number): Promise<any> {
        let params = new HttpParams({ encoder: new WorkingEncoder() })
        params = params.set("prosjektId",prosjektId != null ? prosjektId.toString() : null);
        return <Promise<any>>this.http.get(this.serviceUrl + "GetRapportXlsx", { params, withCredentials: true }).toPromise();
    }
}
