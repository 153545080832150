﻿// ********************************************************************
// ***                                                              ***
// ***            DO NOT MODIFY THIS FILE MANUALLY!                 ***
// *** This file was generated by the API2ANGULAR Service Generator ***
// ***                                                              ***
// ********************************************************************

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpParameterCodec } from '@angular/common/http';
import { environment } from '../../environments/environment';


export class RedigerProsessflytDto {
    public Variabeltyper: VariabeltypeDto[] = [];
    public Roller: RolleDto[] = [];
    public Hovedstatuser: HovedstatusDto[] = [];
    public Skjemaer: SkjemaDto[] = [];
    public OppforingKanOpprettesAv: OppforingKanOpprettesAvDto[] = [];
    public Prosessflyt: ProsessflytDto;
    public Oppdatert: string;
    public Prosessflytgrupper: ProsessflytgruppeDto[] = [];
}

export class HentLikeNummerserierDto {
    public Antall: number;
    public Tooltip: string;
}

export class TestkapittelDto {
    public Testtabeller: TesttabellDto[] = [];
    public Tittel: string;
}

export class VariabeltypeDto {
    public Id: number;
    public Navn: string;
}

export class RolleDto {
    public Id: number;
    public Navn: string;
    public FargeklasseCss: string;
    public Leder: boolean;
}

export class HovedstatusDto {
    public Id: number;
    public Navn: string;
    public Beskrivelse: string;
}

export class SkjemaDto {
    public Id: number;
    public Navn: string;
    public Versjon: number;
}

export class OppforingKanOpprettesAvDto {
    public Id: number;
    public Navn: string;
    public KanOpprettesAvRoller: number[] = [];
}

export class ProsessflytDto {
    public Id: number;
    public Navn: string;
    public Visningsnavn: string;
    public Slettet: boolean;
    public Versjonsnr: number;
    public Opprettet: string;
    public Kode: string;
    public Nummerserie: string;
    public LikeNummerserier: number;
    public LikeNummerserierTooltip: string;
    public Malebrev: boolean;
    public VisKravBelop: boolean;
    public VisAntattBelop: boolean;
    public VisFakturertBelop: boolean;
    public VisOppfolging: boolean;
    public VisPrioritet: boolean;
    public VisFakturertDato: boolean;
    public VisAndreEnnBH: boolean;
    public VisFerdigFakturert: boolean;
    public VisUE: boolean;
    public VisReferanseFraBH: boolean;
    public VisReferanseUE: boolean;
    public VisTilvalg: boolean;
    public VisGodkjentBelop: boolean;
    public VisOmtvistetBelop: boolean;
    public VisForventetGodkjentBelop: boolean;
    public VisGjenstaendeKostnad: boolean;
    public VisFakturertGodkjent: boolean;
    public VisEtatEier: boolean;
    public VisAvklart: boolean;
    public VisFrafalt: boolean;
    public VisOmforent: boolean;
    public VisOmforentUavklart: boolean;
    public VisOpprinneligKrav: boolean;
    public VisReellMerkostnad: boolean;
    public VisUtgarFraKontrakt: boolean;
    public VisKrevetFristforlengelse: boolean;
    public VisOmforentFristforlengelse: boolean;
    public AktiverAnsvarsmatrisefane: boolean;
    public AktiverArkivering: boolean;
    public Beskrivelse: string;
    public RelatertOppforingPakrevet: boolean;
    public Antall: number;
    public Mappenavn: string;
    public Tittelmal: string;
    public Oppforingsnummermal: string;
    public DefaultOppforingsnummermal: string;
    public Statuser: StatusDto[] = [];
    public Prosesstrinn: ProsesstrinnDto[] = [];
    public Variabler: ProsessvariabelDto[] = [];
    public NormalverdierRolleTilgang: RolleTilgangDto[] = [];
    public VisLokasjon: boolean;
    public ProsessflytgruppeId: number;
    public KanRelateresTilProsessflytgrupper: number[] = [];
}

export class StatusDto {
    public Id: number;
    public Navn: string;
    public Guid: string;
    public Beskrivelse: string;
    public HovedstatusId: number;
}

export class ProsesstrinnDto {
    public Id: number;
    public Guid: string;
    public Sortering: number;
    public Tittel: string;
    public UnntattOffentligheten: boolean;
    public Expand: boolean;
    public SvarfristId: number;
    public BehandlesAvRoller: number[] = [];
    public RolleId: number;
    public SendEpostvarsel: boolean;
    public Handlinger: HandlingDto[] = [];
    public AssisterendeRollerListe: RolleDto[] = [];
    public OpprettProsessflyterListe: OpprettOppforingDto[] = [];
}

export class HandlingDto {
    public Id: number;
    public StatusId: string;
    public SkjemaId: number;
    public LinkId: string;
    public Tittel: string;
    public TillatSkjemakopiering: boolean;
    public OpprettProsessdefId: number;
    public VarslingRolleIder: number[] = [];
    public Rolletilgang: RolleTilgangDto[] = [];
    public Opprett: boolean;
    public Varsling: boolean;
    public VarslingRollerListe: RolleDto[] = [];
}

export class RolleTilgangDto {
    public RolleId: number;
    public Navn: string;
    public HarTilgang: boolean;
    public FargeklasseCss: string;
    public Disabled: boolean;
}

export class OpprettOppforingDto {
    public Id: number;
    public Navn: string;
}

export class ProsessvariabelDto {
    public Id: number;
    public Navn: string;
    public Guid: string;
    public StandardVerdi: string;
    public VariabeltypeId: number;
    public Referanser: number;
}

export class ProsessflytgruppeDto {
    public Id: number;
    public Navn: string;
}

export class TesttabellDto {
    public Forberedelser: ForberedelseDto[] = [];
    public Tester: AksjonsDto[];
    public Tittel: string;
}

export class ForberedelseDto {
    public Oppgave: string;
}

export class AksjonsDto {
    public Brukeraksjon: string;
    public Systemrespons: string;
    public Bug: boolean;
    public Ok: boolean;
}

export class WorkingEncoder implements HttpParameterCodec {
    encodeKey(key: string): string { return encodeURIComponent(key); }
    encodeValue(value: string): string { return encodeURIComponent(value); }
    decodeKey(key: string): string { return decodeURIComponent(key); }
    decodeValue(value: string): string { return decodeURIComponent(value); }
}

@Injectable({
   providedIn: "root"
})
export class AdminProsessflytRedigerService {
    private serviceUrl = environment.APIUrl + 'AdminProsessflytRediger/';

    constructor(private http: HttpClient) { } 

    private addArray(params: HttpParams, key: string, value: any): HttpParams {
        if (typeof value === "undefined" || value === null) return params;
        if (Array.isArray(value) && value.length === 0) return params;
        for (let i = 0; i < value.length; i++) params = params.append(key + "[]", value[i].toString());
        return params;
    }

    public Hent(id: number): Promise<RedigerProsessflytDto> {
        let params = new HttpParams({ encoder: new WorkingEncoder() })
        params = params.set("id",id != null ? id.toString() : null);
        return <Promise<RedigerProsessflytDto>>this.http.get(this.serviceUrl + "Hent", { params, withCredentials: true }).toPromise();
    }

    public HentLikeNummerserier(nummerserie: string, id: number): Promise<HentLikeNummerserierDto> {
        let params = new HttpParams({ encoder: new WorkingEncoder() })
        params = params.set("nummerserie",nummerserie != null ? nummerserie.toString() : null);
        params = params.set("id",id != null ? id.toString() : null);
        return <Promise<HentLikeNummerserierDto>>this.http.get(this.serviceUrl + "HentLikeNummerserier", { params, withCredentials: true }).toPromise();
    }

    public Lagre(data: RedigerProsessflytDto): Promise<RedigerProsessflytDto> {
        return <Promise<RedigerProsessflytDto>>this.http.post(this.serviceUrl + "Lagre", data, { withCredentials: true }).toPromise();
    }

    public GenererTestscript(id: number): Promise<TestkapittelDto[]> {
        let params = new HttpParams({ encoder: new WorkingEncoder() })
        params = params.set("id",id != null ? id.toString() : null);
        return <Promise<TestkapittelDto[]>>this.http.get(this.serviceUrl + "GenererTestscript", { params, withCredentials: true }).toPromise();
    }
}
