<div>
    <div class="container" [ng-busy]="busy">
        <nav class="navbar navbar-default">
            <div class="navbar-form nav navbar-nav navbar-right">
                <a id="SourceBtn" class="btn btn-default" *ngIf="data?.Prosessflyt != null" (click)="openSource()">
                    <span class="glyphicon glyphicon-wrench"></span>
                </a>
                <a id="KopiBtn" class="btn btn-default" *ngIf="data?.Prosessflyt != null">
                    <span class="glyphicon glyphicon-duplicate"></span>&nbsp;<span i18n="@@AdminProsessflytRediger1" (click)="lagreKopi()">Lagre kopi ...</span>
                </a>
                <a id="LagreBtn" class="btn btn-success" *ngIf="data?.Prosessflyt != null" (click)="lagre()">
                    <span class="glyphicon glyphicon-ok"></span>&nbsp;<span i18n="@@AdminProsessflytRediger2">Lagre</span>
                </a>
                <a onclick="return navback();" tabindex="-1" class="btn btn-default">
                    <span class="glyphicon glyphicon-menu-left"></span>&nbsp;<span i18n="@@AdminProsessflytRediger3">Gå tilbake</span>
                </a>
                &nbsp;
            </div>
            <div *ngIf="data?.Prosessflyt != null">
                <b class="navbar-brand" i18n="@@AdminProsessflytRediger4">Prosessflyt:</b>
                <span class="navbar-brand" id="Tittel2">{{data.Prosessflyt.Navn}}</span>
                <span class="navbar-brand" style="color:#ff8000;" id="OppføringAntall" *ngIf="data?.Prosessflyt.Antall > 0">
                    <b><span class="glyphicon glyphicon-exclamation-sign"></span> <span i18n="@@AdminProsessflytRediger5">Prosessflyten brukes av</span> </b>
                    <a href="#/Innboks/Oversikt+2+P{{data.Prosessflyt.Id}}">&nbsp;<span data-toggle="tooltip" id="Oppføringteller" class="label label-primary" style="background-color:#ff8000;">{{data.Prosessflyt.Antall}}</span></a>
                </span>
            </div>

        </nav>

        <div *ngIf="data?.Prosessflyt != null">

            <ul class="nav nav-tabs hidden-print">
                <li data-tab="Beskrivelse" role="presentation" class="tabpage" (click)="opentab = 1" [class.active]="opentab == 1"><a i18n="@@AdminProsessflytRediger6">Beskrivelse</a></li>
                <li data-tab="Prosesstrinn" role="presentation" class="tabpage" (click)="openTab(2)" [class.active]="opentab == 2"><a><span i18n="@@AdminProsessflytRediger7">Prosesstrinn</span>&nbsp;<span id="Trinnteller" class="label label-primary">{{data.Prosessflyt.Prosesstrinn.length}}</span></a></li>
                <li data-tab="Statuser" role="presentation" class="tabpage" (click)="opentab = 3" [class.active]="opentab == 3"><a><span i18n="@@AdminProsessflytRediger8">Statuser</span>&nbsp;<span id="Statusteller" class="label label-primary">{{data.Prosessflyt.Statuser.length}}</span></a></li>
                <li data-tab="Variabler" role="presentation" class="tabpage" (click)="opentab = 4" [class.active]="opentab == 4"><a><span i18n="@@AdminProsessflytRediger9">Variabler</span>&nbsp;<span id="Variabelteller" class="label label-primary">{{data.Prosessflyt.Variabler.length}}</span></a></li>
                <li data-tab="Diagram" id="DiagramBtn" role="presentation" class="tabpage" (click)="opentab = 5; tegnDiagram()" [class.active]="opentab == 5"><a i18n="@@AdminProsessflytRediger10">Diagramvisning</a></li>
                <li data-tab="TestscriptTab" id="TestscriptBtn" role="presentation" class="tabpage" *ngIf="prosessflytId > 0" (click)="opentab = 6; hentTestscript()" [class.active]="opentab == 6"><a i18n="@@AdminProsessflytRediger11">Testscript</a></li>
            </ul>
            <div class="spacer"></div>
            <div class="tab-content">
                <div id="Beskrivelse" class="tab-pane active" *ngIf="opentab == 1">
                    <div class="row">
                        <div class="form-group col-sm-8">
                            <label><span i18n="@@AdminProsessflytRediger12">Tittel</span>&nbsp;<small i18n-title="@@AdminProsessflytRediger13" title="Feltet er påkrevet" class="glyphicon glyphicon-star text-danger"></small></label>
                            <input id="Tittel" [(ngModel)]="data.Prosessflyt.Navn" autofocus="" style="margin-top:0" class="form-control input-lg" i18n-placeholder="@@AdminProsessflytRediger14" placeholder="(påkrevet)" />
                        </div>
                        <div class="form-group col-sm-4">
                            <label><span i18n="@@app01Admin04ProsessflytRediger20">Prosessflytgruppe</span>&nbsp;<small i18n-title="@@app01Admin04ProsessflytRediger21" title="Feltet er påkrevet" class="glyphicon glyphicon-star text-danger"></small></label>
                            <ng-select [items]="data.Prosessflytgrupper" bindLabel="Navn" bindValue="Id" [multiple]="false" [clearable]="false" i18n-placeholder="@@app01Admin04ProsessflytRediger22" placeholder="(velg prosessflytgruppe)" [(ngModel)]="data.Prosessflyt.ProsessflytgruppeId"></ng-select>
                        </div>
                        <div class="form-group col-sm-8">
                            <label><span i18n="@@AdminProsessflytRediger15">Visningsnavn</span>&nbsp;<small i18n-title="@@AdminProsessflytRediger16" title="Feltet er påkrevet" class="glyphicon glyphicon-star text-danger"></small></label>
                            <input id="Visningsnavn" [(ngModel)]="data.Prosessflyt.Visningsnavn" autofocus="" style="margin-top:0" class="form-control input-lg" i18n-placeholder="@@AdminProsessflytRediger17" placeholder="(påkrevet)" />
                        </div>
                        <div class="form-group col-sm-1">
                            <label i18n="@@AdminProsessflytRediger18">Versjon</label>
                            <input class="form-control input-lg" type="number" [(ngModel)]="data.Prosessflyt.Versjonsnr" id="Versjonsnr" value="1" readonly="" />
                        </div>
                        <div class="form-group col-sm-3">
                            <label>&nbsp;</label><br />
                            <label>
                                <input type="checkbox" [(ngModel)]="data.Prosessflyt.Malebrev" />
                                <span i18n="@@AdminProsessflytRediger119">Målebrev</span>
                            </label><br />
                            <label>
                                <input type="checkbox" [(ngModel)]="data.Prosessflyt.Slettet" />
                                <span i18n="@@AdminProsessflytRediger19">Utgått (nedlagt / erstattet)</span>
                            </label>
                            <label>
                                <input type="checkbox" [(ngModel)]="data.Prosessflyt.RelatertOppforingPakrevet" id="KrevRelasjoner" />
                                <span i18n="@@AdminProsessflytRediger20">Krev relaterte oppføringer</span>
                            </label>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-sm-6">
                            <div class="row">
                                <div class="col-sm-4">
                                    <label class="form-group">
                                        <span i18n="@@AdminProsessflytRediger21">Nummerserie</span>&nbsp;<small title="Feltet er påkrevet" class="glyphicon glyphicon-star text-danger"></small>&nbsp;
                                        <span class="badge" id="LikeNummerserier" data-toggle="tooltip" [attr.data-original-title]="data.Prosessflyt.LikeNummerserierTooltip">{{data.Prosessflyt.LikeNummerserier}}</span>
                                        <input [(ngModel)]="data.Prosessflyt.Nummerserie" (input)="oppdaterLikeNummerserier()" id="NummerserieInput" maxlength="3" class="form-control" i18n-placeholder="@@AdminProsessflytRediger22" placeholder="(påkrevet)" />
                                    </label>
                                </div>
                                <div class="col-sm-4">
                                    <label class="form-group">
                                        <span i18n="@@AdminProsessflytRediger23">Kode</span>&nbsp;<small i18n-title="@@AdminProsessflytRediger24" title="Feltet er påkrevet" class="glyphicon glyphicon-star text-danger"></small>
                                        <input [(ngModel)]="data.Prosessflyt.Kode" class="form-control" placeholder="(påkrevet)" />
                                    </label>
                                </div>
                                <div class="col-sm-4">
                                    <label class="form-group">
                                        <span i18n="@@AdminProsessflytRediger25">Mappenavn i arkiv</span>
                                        <input [(ngModel)]="data.Prosessflyt.Mappenavn" class="form-control" />
                                    </label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <label class="form-group">
                                        <span i18n="@@AdminProsessflytRediger26">Tittelmal</span>
                                        <input [(ngModel)]="data.Prosessflyt.Tittelmal" class="form-control" />
                                    </label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <label class="form-group">
                                        <span i18n="@@AdminProsessflytRediger27">Oppføringsnummermal</span>
                                        <input [(ngModel)]="data.Prosessflyt.Oppforingsnummermal" class="form-control" id="Oppføringsnummermal" [placeholder]="data.Prosessflyt.DefaultOppforingsnummermal" />
                                    </label>
                                    <p i18n="@@app01Admin04ProsessflytRediger18">* Eksisterende oppføringsnr vil bli oppdatert hvis en eller flere av følgende endres: Prosessflyt.Oppføringsnummermal, Prosessflyt.Kode eller Kontrakt.Kontraktsnr</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 form-group">
                                    <label i18n="@@app01Admin04ProsessflytRediger23" *ngIf="!data.Prosessflyt.KanRelateresTilProsessflytgrupper || data.Prosessflyt.KanRelateresTilProsessflytgrupper.length == 0">Velg her for å gjøre begrensninger i relatering til andre oppføringstyper</label>
                                    <label i18n="@@app01Admin04ProsessflytRediger24" *ngIf="data.Prosessflyt.KanRelateresTilProsessflytgrupper &amp;&amp; data.Prosessflyt.KanRelateresTilProsessflytgrupper.length > 0">Relaterte oppføringer begrenser seg til</label>
                                    <ng-select [items]="data.Prosessflytgrupper" bindLabel="Navn" bindValue="Id" [multiple]="true" [clearable]="true" i18n-placeholder="@@app01Admin04ProsessflytRediger25" placeholder="(ingen begrensninger)" [(ngModel)]="data.Prosessflyt.KanRelateresTilProsessflytgrupper"></ng-select>
                                </div>
                            </div>

                        </div>
                        <div class="col-sm-3" *ngIf="data?.Prosessflyt.NormalverdierRolleTilgang != null">
                            <div class="form-group">
                                <span i18n="@@AdminProsessflytRediger28">Standard innsyn pr. rolle:</span>
                                <div class="normalverdiercontainer">
                                    <h4 *ngFor="let t of data.Prosessflyt.NormalverdierRolleTilgang; let i = index">
                                        <label [ngClass]="&#39;label label-&#39; + t.FargeklasseCss">
                                            <input type="checkbox" [(ngModel)]="t.HarTilgang" (change)="normalverdieRolleTilgangChange(i)" class="Standardverdi" />
                                            {{t.Navn}}
                                        </label>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">

                                <label><input type="checkbox" [(ngModel)]="data.Prosessflyt.VisOppfolging" /> <span i18n="@@AdminProsessflytRediger29">Vis fane 'Oppfølging'</span></label><br />

                                <div *ngIf="data?.Prosessflyt.VisOppfolging" style="margin-left:15px;">

                                    <label><input type="checkbox" [(ngModel)]="data.Prosessflyt.VisAvklart" /> <span i18n="@@AdminProsessflytRediger30">Vis 'Avklart'</span></label><br />
                                    <label><input type="checkbox" [(ngModel)]="data.Prosessflyt.VisFrafalt" /> <span i18n="@@AdminProsessflytRediger31">Vis 'Frafalt'</span></label><br />
                                    <label><input type="checkbox" [(ngModel)]="data.Prosessflyt.VisOmforent" /> <span i18n="@@AdminProsessflytRediger32">Vis 'Omforent'</span></label><br />
                                    <label><input type="checkbox" [(ngModel)]="data.Prosessflyt.VisOmforentUavklart" /> <span i18n="@@AdminProsessflytRediger33">Vis 'Omforent uavklart'</span></label><br />
                                    <label><input type="checkbox" [(ngModel)]="data.Prosessflyt.VisOpprinneligKrav" /> <span i18n="@@AdminProsessflytRediger34">Vis 'Opprinnelig krav'</span></label><br />
                                    <label><input type="checkbox" [(ngModel)]="data.Prosessflyt.VisReellMerkostnad" /> <span i18n="@@AdminProsessflytRediger35">Vis 'Reell merkostnad'</span></label><br />
                                    <label><input type="checkbox" [(ngModel)]="data.Prosessflyt.VisUtgarFraKontrakt" /> <span i18n="@@AdminProsessflytRediger36">Vis 'Utgår fra kontrakt'</span></label><br />

                                    <br />

                                    <label><input type="checkbox" [(ngModel)]="data.Prosessflyt.VisKravBelop" /> <span i18n="@@AdminProsessflytRediger37">Vis 'Krevet beløp'</span></label><br />
                                    <label><input type="checkbox" [(ngModel)]="data.Prosessflyt.VisAntattBelop" /> <span i18n="@@AdminProsessflytRediger38">Vis 'Antatt beløp'</span></label><br />
                                    <label><input type="checkbox" [(ngModel)]="data.Prosessflyt.VisFakturertBelop" /> <span i18n="@@AdminProsessflytRediger39">Vis 'Fakturert beløp'</span></label><br />

                                    <br />

                                    <label><input type="checkbox" [(ngModel)]="data.Prosessflyt.VisUE" /> <span i18n="@@AdminProsessflytRediger40">Vis 'UE'</span></label><br />

                                    <br />

                                    <label><input type="checkbox" [(ngModel)]="data.Prosessflyt.VisFakturertDato" /> <span i18n="@@AdminProsessflytRediger41">Vis 'Fakturert dato'</span></label><br />
                                    <label><input type="checkbox" [(ngModel)]="data.Prosessflyt.VisAndreEnnBH" /> <span i18n="@@AdminProsessflytRediger42">Vis 'Andre enn BH'</span></label><br />
                                    <label><input type="checkbox" [(ngModel)]="data.Prosessflyt.VisFerdigFakturert" /> <span i18n="@@AdminProsessflytRediger43">Vis 'Ferdig fakturert'</span></label><br />

                                    <label><input type="checkbox" [(ngModel)]="data.Prosessflyt.VisReferanseFraBH" /> <span i18n="@@AdminProsessflytRediger44">Vis 'Referanse fra BH'</span></label><br />
                                    <label><input type="checkbox" [(ngModel)]="data.Prosessflyt.VisReferanseUE" /> <span i18n="@@AdminProsessflytRediger45">Vis 'Referanse UE'</span></label><br />
                                    <label><input type="checkbox" [(ngModel)]="data.Prosessflyt.VisTilvalg" /> <span i18n="@@AdminProsessflytRediger46">Vis 'Tilvalg'</span></label><br />

                                    <br />

                                    <label><input type="checkbox" [(ngModel)]="data.Prosessflyt.VisGodkjentBelop" /> <span i18n="@@AdminProsessflytRediger47">Vis 'Godkjent beløp'</span></label><br />
                                    <label><input type="checkbox" [(ngModel)]="data.Prosessflyt.VisOmtvistetBelop" /> <span i18n="@@AdminProsessflytRediger48">Vis 'Omtvistet beløp'</span></label><br />
                                    <label><input type="checkbox" [(ngModel)]="data.Prosessflyt.VisForventetGodkjentBelop" /> <span i18n="@@AdminProsessflytRediger49">Vis 'Forventet godkjent beløp'</span></label><br />
                                    <label><input type="checkbox" [(ngModel)]="data.Prosessflyt.VisGjenstaendeKostnad" /> <span i18n="@@AdminProsessflytRediger50">Vis 'Gjenstående kostnad'</span></label><br />
                                    <label><input type="checkbox" [(ngModel)]="data.Prosessflyt.VisFakturertGodkjent" /> <span i18n="@@AdminProsessflytRediger51">Vis 'Fakturert - godkjent'</span></label><br />

                                    <br />

                                    <label><input type="checkbox" [(ngModel)]="data.Prosessflyt.VisEtatEier" /> <span i18n="@@AdminProsessflytRediger118">Vis 'Etat / Eier'</span></label><br />

                                    <br />

                                    <label><input type="checkbox" [(ngModel)]="data.Prosessflyt.VisKrevetFristforlengelse" /> <span i18n="@@app01Admin04ProsessflytRediger26">Vis 'Krevet fristforlengelse'</span> </label>
                                    <label><input type="checkbox" [(ngModel)]="data.Prosessflyt.VisOmforentFristforlengelse" /> <span i18n="@@app01Admin04ProsessflytRediger27">Vis 'Omforent fristforlengelse'</span> </label>

                                </div>

                                <label><input type="checkbox" [(ngModel)]="data.Prosessflyt.VisLokasjon" /> <span i18n="@@app01Admin04ProsessflytRediger19">Vis fane 'Lokasjoner'</span></label><br />

                                <label><input type="checkbox" [(ngModel)]="data.Prosessflyt.VisPrioritet" /> <span i18n="@@AdminProsessflytRediger52">Vis felt 'Prioritet'</span></label><br />

                                <br />

                                <label><input type="checkbox" [(ngModel)]="data.Prosessflyt.AktiverAnsvarsmatrisefane" /> <span i18n="@@AdminProsessflytRediger53">Aktiver fane med ansvarsmatrise på oppføringer</span> </label><br />
                                <br />
                                <label><input type="checkbox" [(ngModel)]="data.Prosessflyt.AktiverArkivering" /> <span i18n="@@AdminProsessflytRediger54">Aktiver arkivering av oppføringer</span></label><br />

                            </div>

                        </div>
                    </div>
                    <div class="form-group">
                        <label i18n="@@AdminProsessflytRediger55">Beskrivelse</label>
                        <textarea style="height:20em" class="form-control" [(ngModel)]="data.Prosessflyt.Beskrivelse"></textarea>
                    </div>
                </div>
                <div id="Prosesstrinn" class="tab-pane active" *ngIf="opentab == 2">
                    <label i18n="@@AdminProsessflytRediger56">Prosesstrinn:</label>
                    
                    <div cdkDropList="" (cdkDropListDropped)="dropProsesstrinn($event)" class="prosessflyt-list">
                        <div *ngFor="let p of data.Prosessflyt.Prosesstrinn; let i = index" cdkDrag="" cdkDragLockAxis="y" class="prosessflyt-box">
                            <div [ngClass]="&#39;panel panel-&#39; + getProsesstrinnClass(i)">
                                <div class="panel-heading">
                                    <div class="pull-right">
                                        <button class="btn btn-xs btn-default" [class.active]="p.Expand" (click)="p.Expand = !p.Expand">
                                            <span class="glyphicon glyphicon-plus"></span>
                                        </button>
                                    </div>
                                    <button class="btn btn-xs btn-default pull-right" style="margin-right:0.5em" cdkDragHandle="">
                                        <span class="glyphicon glyphicon-hand-up"></span>
                                    </button>
                                    <button class="btn btn-xs btn-default pull-right" style="margin-right:0.5em" (click)="slettProsesstrinn(i)">
                                        <span class="glyphicon glyphicon-trash"></span>
                                    </button>

                                    <h4 contenteditable="true" class="panel-title" (blur)="tittelChanged(i, $event.target.innerText)" i18n-data-placeholder="@@AdminProsessflytRediger57" data-placeholder="(tittel)">{{p.Tittel}}</h4>
                                    <input type="hidden" name="Id" />
                                    <input type="hidden" name="Sortering" />
                                    <input type="hidden" name="guid" data-func="guid" />
                                </div>
                                <div *ngIf="p.Expand">
                                    <div class="panel-body">
                                        <div class="row">
                                            <div class="col-sm-3 form-group">
                                                <label i18n="@@AdminProsessflytRediger58">Utførende rolle:</label>
                                                <ng-select [items]="data.Roller" bindLabel="Navn" bindValue="Id" [multiple]="false" [clearable]="false" placeholder="(ikke valgt)" [ngModel]="p.RolleId" (change)="rolleChanged($event, i)">
                                                </ng-select>
                                            </div>
                                            <div class="col-sm-3 form-group">
                                                <label i18n="@@AdminProsessflytRediger59">Svarfrist (dager)</label>
                                                <ng-select [items]="data.Prosessflyt.Variabler" bindLabel="Navn" bindValue="Guid" [multiple]="false" [clearable]="true" placeholder="(ingen)" [(ngModel)]="p.SvarfristId">
                                                </ng-select>
                                            </div>
                                            <div class="col-sm-3 form-group">
                                                <label i18n="@@AdminProsessflytRediger60">Assisterende roller</label>
                                                <ng-select [items]="p.AssisterendeRollerListe" bindLabel="Navn" bindValue="Id" [multiple]="true" [clearable]="true" placeholder="(ingen)" [(ngModel)]="p.BehandlesAvRoller" (change)="kanBehandlesAvChanged(i)" (add)="assisterendeAdded($event, i)" (remove)="assisterendeRemoved($event, i)">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-3">
                                                <label>
                                                    <input type="checkbox" [(ngModel)]="p.UnntattOffentligheten" />&nbsp;
                                                    <span i18n="@@AdminProsessflytRediger61">Unntatt offentligheten</span>
                                                </label>
                                            </div>
                                            <div class="col-sm-4 epostvarsling">
                                                <label i18n-title="@@AdminProsessflytRediger62" title="Send e-postvarsel til utførende rolle når oppføringen kommer til dette trinnet (for brukere som har varsling aktivert)">
                                                    <input type="checkbox" [(ngModel)]="p.SendEpostvarsel" />&nbsp;
                                                    <span i18n="@@AdminProsessflytRediger63">Send e-postvarsel til utførende rolle.</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <table class="table table-striped" style="margin-bottom:0">
                                        <thead>
                                            <tr>
                                                <th style="width:25%" rowspan="2" i18n="@@AdminProsessflytRediger64">Legg inn handlingsalternativ(er):</th>
                                                <th style="width:25%" rowspan="2" i18n="@@AdminProsessflytRediger65">Skjema til utfylling:</th>
                                                <th style="width:25%" rowspan="2" i18n="@@AdminProsessflytRediger66">Sett status:</th>
                                                <th style="width:25%" rowspan="2" i18n="@@AdminProsessflytRediger67">Gå til trinn:</th>
                                                <th i18n="@@AdminProsessflytRediger115" i18n-title="@@AdminProsessflytRediger68" title="Tillatt kopiering av innhold fra forrige innsendte skjema av samme type" rowspan="2">Kopi</th>
                                                <th align="center" i18n="@@AdminProsessflytRediger116" i18n-title="@@AdminProsessflytRediger69" title="Opprett ny oppføring ved utførelse av dette alternativet" rowspan="2">Opprett</th>
                                                <th align="center" i18n="@@AdminProsessflytRediger117" i18n-title="@@AdminProsessflytRediger70" title="Varsle valgte roller ved utførelse av dette alternativet" rowspan="2">Utvidet varsling</th>
                                                <th align="center" rowspan="2">
                                                    <table>
                                                        <tr>
                                                            <td class="text-center" i18n="@@AdminProsessflytRediger71">Innsyn</td>
                                                        </tr>
                                                        <tr class="tilganglegende nobr">
                                                            <td *ngFor="let n of data.Prosessflyt.NormalverdierRolleTilgang" style="padding-left: 0;padding-right: 0;margin-right:1px;width:12px;height:12px;display:inline-block;" data-toggle="tooltip" [attr.data-original-title]="n.Navn" [ngClass]="&#39;label label-&#39; + n.FargeklasseCss"></td>
                                                        </tr>
                                                    </table>
                                                </th>
                                                <th class="nopadd nobr tight text-right">
                                                    <a class="btn btn-default" (click)="leggTilHandling(i)"><span class="glyphicon glyphicon-plus-sign"></span></a>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td></td>
                                            </tr>
                                        </thead>
                                        <tbody i18n-data-placeholder="@@AdminProsessflytRediger72" data-placeholder="Ingen videre trinn, prosessen avsluttes." cdkDropList="" (cdkDropListDropped)="dropHandling($event, i)">
                                            <tr class="Handling" *ngFor="let h of p.Handlinger; let hindex = index;" cdkDrag="" cdkDragLockAxis="y" style="width: 100%; background: white;">
                                                <td contenteditable="true" [(ngModel)]="h.Tittel" i18n-data-placeholder="@@AdminProsessflytRediger73" data-placeholder="(tittel)"></td>
                                                <td>
                                                    <ng-select class="custom" [items]="data.Skjemaer" bindLabel="Navn" bindValue="Id" [multiple]="false" [clearable]="true" placeholder="(ingen)" [(ngModel)]="h.SkjemaId">
                                                    </ng-select>
                                                </td>
                                                <td>
                                                    <ng-select class="custom" [items]="data.Prosessflyt.Statuser" bindLabel="Navn" bindValue="Guid" [multiple]="false" [clearable]="true" placeholder="(ingen endring)" [(ngModel)]="h.StatusId">
                                                    </ng-select>
                                                </td>
                                                <td>
                                                    <ng-select class="custom" [items]="data.Prosessflyt.Prosesstrinn" bindLabel="Tittel" bindValue="Guid" [multiple]="false" [clearable]="true" placeholder="(velg trinn)" [(ngModel)]="h.LinkId">
                                                    </ng-select>
                                                </td>
                                                <td class="text-center">
                                                    <input type="checkbox" [(ngModel)]="h.TillatSkjemakopiering" />
                                                </td>
                                                <td align="center">
                                                    <input type="checkbox" [(ngModel)]="h.Opprett" />
                                                    <div *ngIf="h.Opprett" class="selectForOpprett" style="min-width:120px; text-align:left;">
                                                        <ng-select class="custom" [items]="p.OpprettProsessflyterListe" bindLabel="Navn" bindValue="Id" [multiple]="false" [clearable]="true" placeholder="(velg prosessflyt)" [(ngModel)]="h.OpprettProsessdefId">
                                                        </ng-select>
                                                    </div>
                                                </td>
                                                <td align="center">
                                                    <input type="checkbox" [(ngModel)]="h.Varsling" />
                                                    <div *ngIf="h.Varsling" class="selectForVarsling" style="min-width:120px; text-align:left;">
                                                        <ng-select class="custom" [items]="h.VarslingRollerListe" bindLabel="Navn" bindValue="Id" [multiple]="true" [clearable]="true" placeholder="(velg roller)" [(ngModel)]="h.VarslingRolleIder">
                                                        </ng-select>
                                                    </div>
                                                </td>
                                                <td align="center">
                                                    <table>
                                                        <tr data-remove="1">
                                                            <td *ngFor="let r of h.Rolletilgang; let rindex = index;" class="" style="padding-left: 0;padding-right: 0;">
                                                                <input type="checkbox" [(ngModel)]="r.HarTilgang" [attr.data-original-title]="r.Navn" class="rolletilgang" [disabled]="r.Disabled" (change)="oppdaterVarsling(i, hindex)" [class.inherited]="data.Prosessflyt.NormalverdierRolleTilgang[rindex].HarTilgang == r.HarTilgang" />
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                                <td class="nopadd tight nobr">
                                                    <a class="btn btn-default" (click)="slettHandling(i, hindex)"><span class="glyphicon glyphicon-trash"></span> </a>
                                                    <a class="btn btn-default" cdkDragHandle=""><span class="glyphicon glyphicon-hand-up"></span></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-default" data-action="add" data-target="Prosesstrinn" i18n="@@AdminProsessflytRediger78" (click)="leggTilProsesstrinn()">Legg til trinn</button>
                </div>
                <div id="Statuser" class="tab-pane active" *ngIf="opentab == 3">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th i18n="@@AdminProsessflytRediger79">Navn</th>
                                <th i18n="@@AdminProsessflytRediger80">Beskrivelse</th>
                                <th i18n="@@AdminProsessflytRediger81">Hovedstatus</th>
                                <th class="tight"></th>
                            </tr>
                        </thead>
                        <tbody data-array="Statuser" data-autoinsert="1" data-remove="1">
                            <tr *ngFor="let s of data.Prosessflyt.Statuser; let statusindex = index">
                                <td class="inputcol nopadd">
                                    <input class="form-control" [(ngModel)]="s.Navn" maxlength="250" i18n-placeholder="@@AdminProsessflytRediger82" placeholder="(tekst)" />
                                </td>
                                <td contenteditable="" [(ngModel)]="s.Beskrivelse" i18n-data-placeholder="@@AdminProsessflytRediger83" data-placeholder="(beskrivelse)"></td>
                                <td class="inputcol nopadd">
                                    <ng-select [items]="data.Hovedstatuser" bindLabel="Navn" bindValue="Id" [multiple]="false" [clearable]="false" placeholder="(velg hovedstatus)" [(ngModel)]="s.HovedstatusId">
                                    </ng-select>
                                </td>
                                <td class="nopadd">
                                    <a class="btn btn-default" (click)="slettStatus(statusindex)"><span class="glyphicon glyphicon-trash"></span></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button class="btn btn-primary" i18n="@@app01Admin04ProsessflytRediger1" (click)="leggTilStatus()">Legg til ny status</button>
                </div>
                <div id="Variabler" class="tab-pane active" *ngIf="opentab == 4">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th i18n="@@AdminProsessflytRediger84">Navn</th>
                                <th i18n="@@AdminProsessflytRediger85">Type</th>
                                <th i18n="@@AdminProsessflytRediger86">Standard verdi</th>
                                <th i18n="@@AdminProsessflytRediger87">Referanser</th>
                                <th class="tight"></th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let v of data.Prosessflyt.Variabler; let variabelindex = index" data-autoinsert="1" data-remove="1">
                            <tr>
                                <td class="inputcol nopadd">
                                    <input class="form-control" [(ngModel)]="v.Navn" i18n-placeholder="@@AdminProsessflytRediger88" placeholder="(tekst)" />
                                </td>
                                <td class="inputcol nopadd">
                                    <ng-select [items]="data.Variabeltyper" bindLabel="Navn" bindValue="Id" [multiple]="false" [clearable]="false" placeholder="(velg variabeltype)" [(ngModel)]="v.VariabeltypeId">
                                    </ng-select>
                                </td>
                                <td class="inputcol nopadd">
                                    <input class="form-control" [(ngModel)]="v.StandardVerdi" i18n-placeholder="@@AdminProsessflytRediger89" placeholder="(tekst)" />
                                </td>
                                <td>{{v.Referanser}}</td>
                                <td class="nopadd">
                                    <a class="btn btn-default" (click)="slettVariabel(variabelindex)"><span class="glyphicon glyphicon-trash"></span></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button class="btn btn-primary" i18n="@@app01Admin04ProsessflytRediger2" (click)="leggTilVariabel()">Legg til ny variabel</button>
                </div>
                <div id="Diagram" class="tab-pane active" *ngIf="opentab == 5">
                    <table class="table table-striped table-bordered">
                        <tr id="Diagramheaders"></tr>
                        <tr id="Diagramcontent" [class.visknappetekster]="visknappetekster"></tr>
                    </table>
                    <div>
                        <label><input type="checkbox" [(ngModel)]="visknappetekster" /> <span i18n="@@AdminProsessflytRediger90">Vis knappetekster</span></label>
                    </div>
                </div>
                <div id="TestscriptTab" class="tab-pane active" *ngIf="opentab == 6">
                    <button type="button" id="LastNedScriptDocx" class="btn btn-default btn-sm pull-right" (click)="lastNedTestscript()"><span class="glyphicon glyphicon-download-alt"></span>&nbsp;<span i18n="@@AdminProsessflytRediger91">Last ned</span>&nbsp; <small class="badge">DOCX</small></button>
                    <div id="Testscript">
                        <div *ngFor="let kap of testscript; let kindex = index;">
                            <h1>{{kap.Tittel}}</h1>
                            <div *ngFor="let tab of kap.Testtabeller; let tindex = index;">
                                <h2>{{kindex + 1}}.{{tindex + 1}}. {{tab.Tittel}}</h2>

                                <div *ngIf="tab.Forberedelser.length > 0">
                                    <h3><span i18n="@@app01Admin04ProsessflytRediger3">Forberedelser for test av</span> &laquo;{{tab.Tittel}}&raquo;</h3>
                                    <table style="width:50%" class="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th class="tight" i18n="@@app01Admin04ProsessflytRediger4">Nr</th>
                                                <th i18n="@@app01Admin04ProsessflytRediger5">Oppgave</th>
                                                <th class="tight" i18n="@@app01Admin04ProsessflytRediger6">Utført</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let f of tab.Forberedelser; let forberedelseindex = index">
                                                <td>{{getTestscriptTeller(kindex, tindex, forberedelseindex, null)}}</td>
                                                <td [innerHTML]="f.Oppgave"></td>
                                                <td class="text-center"><input class="ok" name="O" type="checkbox" /></td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>

                                <div *ngIf="tab.Tester.length > 0">
                                    <h3><span i18n="@@app01Admin04ProsessflytRediger7">Test av</span> &laquo;{{tab.Tittel}}&raquo; <span i18n="@@app01Admin04ProsessflytRediger8">funksjon</span></h3>
                                    <table class="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th class="tight" i18n="@@app01Admin04ProsessflytRediger9">Nr</th>
                                                <th style="width:50%" i18n="@@app01Admin04ProsessflytRediger10">Brukeraksjon</th>
                                                <th style="width:50%" i18n="@@app01Admin04ProsessflytRediger11">Systemrespons</th>
                                                <th class="tight" i18n="@@app01Admin04ProsessflytRediger12">Bug</th>
                                                <th class="tight" i18n="@@app01Admin04ProsessflytRediger13">OK</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let t of tab.Tester; let testindex = index" [class.warning]="t.Bug" [class.success]="t.Ok">
                                                <td>{{getTestscriptTeller(kindex, tindex, null, testindex)}}</td>
                                                <td [innerHTML]="t.Brukeraksjon"></td>
                                                <td [innerHTML]="t.Systemrespons"></td>
                                                <td class="text-center"><input [(ngModel)]="t.Bug" type="checkbox" (change)="bugChanged(kindex, tindex, testindex)" /></td>
                                                <td class="text-center"><input [(ngModel)]="t.Ok" type="checkbox" (change)="okChanged(kindex, tindex, testindex)" /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
    
    <div style="display:none">
        <div i18n="@@AdminProsessflytRediger94" #Text1="">Vil du opprette en kopi av denne prosessflyten?</div>
        <div i18n="@@AdminProsessflytRediger96" #Text3="">kopi</div>
        <div i18n="@@AdminProsessflytRediger97" #Text4="">(IKKE VALGT)</div>
        <div i18n="@@AdminProsessflytRediger98" #Text5="">(ingen)</div>
        <div i18n="@@AdminProsessflytRediger99" #Text6="">(ingen endring)</div>
        <div i18n="@@AdminProsessflytRediger101" #Text8="">Rediger prosessflyt</div>

        <div i18n="@@AdminProsessflytRediger109" #Text16="">Prosessflyten brukes av</div>
        <div i18n="@@AdminProsessflytRediger110" #Text17="">oppføring(er)</div>

        <div i18n="@@AdminProsessflytRediger100" #Text7="">Kommer senere...</div>
        <div i18n="@@app01Admin04ProsessflytRediger14" #SlettStatusMeldingTekst="">Vil du slette valgt status?</div>
        <div i18n="@@app01Admin04ProsessflytRediger15" #SlettVariabelMeldingTekst="">Vil du slette valgt variabel?</div>
        <div i18n="@@app01Admin04ProsessflytRediger16" #SlettHandlingMeldingTekst="">Vil du slette valgt handlingsalternativ?</div>
        <div i18n="@@app01Admin04ProsessflytRediger17" #SlettProsesstrinnMeldingTekst="">Vil du slette valgt prosesstrinn?</div>
    </div>
</div>