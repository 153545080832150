﻿import { Component, ViewChild, ElementRef } from '@angular/core';
import { ProsjektadminRedigerService, HovedprosjektDto, BrukergruppeDto, BrukerkontoDto } from '../99 Services/ProsjektadminRediger.service';
import { ModalsService } from '../80 Modals/Service';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { RapportOppforingerService, ProsjektDto, OppforingerDto } from '../99 Services/RapportOppforinger.service';
import { environment } from 'Angular/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
    templateUrl: "./Oppforinger.html",
    styleUrls: ["./Oppforinger.css"]
})
export class RapportOppforingerComponent {

    constructor(private service: RapportOppforingerService,
        private modalService: ModalsService,
        private titleService: Title) { }

    busy: any;
    valgtHovedprosjektId: number;
    valgtHovedprosjektTittel: string;
    hovedprosjekter: ProsjektDto[] = [];

    displayedColumns: string[] = [];
    dataSource: MatTableDataSource<OppforingerDto> = new MatTableDataSource<OppforingerDto>();
    @ViewChild(MatPaginator) paginator: MatPaginator;
    filter: string;

    select2optionsSingle: Select2Options = {
        multiple: false,
        escapeMarkup: (markup: string) => { return markup },
        templateSelection: (object: Select2SelectionObject) => { return $("<span/>").html(object.text) },
        templateResult: (object: Select2SelectionObject) => { return $("<span/>").html(object.text) },
        theme: "bootstrap",
        dropdownAutoWidth: true,
        width: "auto"
    };

    
    @ViewChild("Tittel") TittelRef: ElementRef;

    ngAfterViewInit() {
        //this.titleService.setTitle($(this.TittelRef.nativeElement).text());
    }

    ngOnInit() {
        this.busy = this.service.GetProsjekter().then(res => {
            this.hovedprosjekter = res;
            this.valgtHovedprosjektId = res[0].id;
            this.oppdater();
        }, fail => {
            this.modalService.Alert(fail.errror.Message);
        })
    }

    

    oppdater() {
        this.busy = this.service.GetRapport(this.valgtHovedprosjektId).then(res => {
            this.valgtHovedprosjektTittel = this.hovedprosjekter.find(h => h.id == this.valgtHovedprosjektId).text;

            this.displayedColumns = ["Innsendt", "Hovedkontrakt", "Oppføringsnummer"];

            if (res.ArbeidsflytBim2shareNo) { this.displayedColumns.push("Type"); }

            this.displayedColumns.push("Tittel");

            if (res.Bymiljøetaten) {
                this.displayedColumns.push("EtatEier");
                this.displayedColumns.push("Krevetbeløp");
                this.displayedColumns.push("Antattbeløp");
                this.displayedColumns.push("Omforentbeløp");
                this.displayedColumns.push("Fakturertbeløp");
                this.displayedColumns.push("Opprinneligkrav");
                this.displayedColumns.push("Omtvistetbeløp");
            }
            else if (res.ArbeidsflytBim2shareNo) {
                if (res.VisOppfølgingsKolonner.VisOmforentUavklart) this.displayedColumns.push("Omforentuavklartbeløp");
                if (res.VisOppfølgingsKolonner.VisOmforent) this.displayedColumns.push("Omforentbeløp");
                if (res.VisOppfølgingsKolonner.VisOpprinneligKrav) this.displayedColumns.push("Opprinneligkrav");
                if (res.VisOppfølgingsKolonner.VisFrafalt) this.displayedColumns.push("Frafaltbeløp");
                if (res.VisOppfølgingsKolonner.VisUtgarFraKontrakt) this.displayedColumns.push("UtgårfrakontraktGjøresopppåprosess");
                if (res.VisOppfølgingsKolonner.VisReellMerkostnad) this.displayedColumns.push("Reellmerkostnad");
                if (res.VisOppfølgingsKolonner.VisAvklart) this.displayedColumns.push("Avklartbådeøkonomiogtid");
                if (res.VisOppfølgingsKolonner.VisKravBelop) this.displayedColumns.push("Krevetbeløp");
                if (res.VisOppfølgingsKolonner.VisAntattBelop) this.displayedColumns.push("Antattbeløp");
                if (res.VisOppfølgingsKolonner.VisFakturertBelop) this.displayedColumns.push("Fakturertbeløp");
                if (res.VisOppfølgingsKolonner.VisFakturertDato) this.displayedColumns.push("Fakturertdato");
                if (res.VisOppfølgingsKolonner.VisAndreEnnBH) this.displayedColumns.push("AndreennBH");
                if (res.VisOppfølgingsKolonner.VisFerdigFakturert) this.displayedColumns.push("Ferdigfakturert");
                if (res.VisOppfølgingsKolonner.VisReferanseFraBH) this.displayedColumns.push("ReferansefraBH");
                if (res.VisOppfølgingsKolonner.VisReferanseUE) this.displayedColumns.push("ReferanseUE");
                if (res.VisOppfølgingsKolonner.VisTilvalg) this.displayedColumns.push("Tilvalg");
                if (res.VisOppfølgingsKolonner.VisGodkjentBelop) this.displayedColumns.push("Godkjentbeløp");
                if (res.VisOppfølgingsKolonner.VisOmtvistetBelop) this.displayedColumns.push("Omtvistetbeløp");
                if (res.VisOppfølgingsKolonner.VisForventetGodkjentBelop) this.displayedColumns.push("Forventetgodkjentbeløp");
                if (res.VisOppfølgingsKolonner.VisGjenstaendeKostnad) this.displayedColumns.push("Gjenståendekostnad");
                if (res.VisOppfølgingsKolonner.VisFakturertGodkjent) this.displayedColumns.push("Fakturertgodkjent");
                if (res.VisOppfølgingsKolonner.VisUE) this.displayedColumns.push("UE");
                if (res.VisOppfølgingsKolonner.VisEtatEier) this.displayedColumns.push("EtatEier");
                if (res.VisOppfølgingsKolonner.VisKrevetFristforlengelse) this.displayedColumns.push("Krevetfristforlengelse");
                if (res.VisOppfølgingsKolonner.VisOmforentFristforlengelse) this.displayedColumns.push("Omforentfristforlengelse");
            }
            else {
                this.displayedColumns.push("Omforentuavklartbeløp");
                this.displayedColumns.push("Omforentbeløp");
                this.displayedColumns.push("Opprinneligkrav");
                this.displayedColumns.push("Frafaltbeløp");
                this.displayedColumns.push("UtgårfrakontraktGjøresopppåprosess");
                this.displayedColumns.push("Reellmerkostnad");
                this.displayedColumns.push("Avklartbådeøkonomiogtid");
                this.displayedColumns.push("Krevetbeløp");
                this.displayedColumns.push("Antattbeløp");
                this.displayedColumns.push("Fakturertbeløp");
            }

            this.displayedColumns.push("Sistemeldingsdato");
            this.displayedColumns.push("Oppføringenliggerhos");
            this.displayedColumns.push("Hovedstatus");
            this.displayedColumns.push("Lokalstatus");
            this.displayedColumns.push("Merknader");


            this.dataSource = new MatTableDataSource(res.Rapport);
            this.dataSource.paginator = this.paginator;
            
            this.dataSource.filterPredicate = (data: OppforingerDto, filter: string) => {
                let txt = document.createElement("textarea");
                txt.innerHTML = data.Merknader.replace(/<.*?>/g, '').toLowerCase();
                let merknader = txt.value;

                return (this.displayedColumns.indexOf("Hovedkontrakt") != -1 && data.Hovedkontrakt != null && data.Hovedkontrakt.toLowerCase().includes(filter.toLowerCase())) ||
                    (this.displayedColumns.indexOf("Oppføringsnummer") != -1 && data.Oppforingsnr != null && data.Oppforingsnr.toLowerCase().includes(filter.toLowerCase())) ||
                    (this.displayedColumns.indexOf("Type") != -1 && data.Oppforingstype != null && data.Oppforingstype.toLowerCase().includes(filter.toLowerCase())) ||
                    (this.displayedColumns.indexOf("Tittel") != -1 && data.Tittel != null && data.Tittel.toLowerCase().includes(filter.toLowerCase())) ||
                    (this.displayedColumns.indexOf("EtatEier") != -1 && data.EtatEier != null && data.EtatEier.toLowerCase().includes(filter.toLowerCase())) ||
                    (this.displayedColumns.indexOf("AndreennBH") != -1 && data.AndreEnnBH != null && data.AndreEnnBH.toLowerCase().includes(filter.toLowerCase())) ||
                    (this.displayedColumns.indexOf("Ferdigfakturert") != -1 && data.FerdigFakturert != null && data.FerdigFakturert.toLowerCase().includes(filter.toLowerCase())) ||
                    (this.displayedColumns.indexOf("ReferansefraBH") != -1 && data.ReferanseFraBH != null && data.ReferanseFraBH.toLowerCase().includes(filter.toLowerCase())) ||
                    (this.displayedColumns.indexOf("ReferanseUE") != -1 && data.ReferanseUE != null && data.ReferanseUE.toLowerCase().includes(filter.toLowerCase())) ||
                    (this.displayedColumns.indexOf("Tilvalg") != -1 && data.Tilvalg != null && data.Tilvalg.toLowerCase().includes(filter.toLowerCase())) ||
                    (this.displayedColumns.indexOf("UE") != -1 && data.UE != null && data.UE.toLowerCase().includes(filter.toLowerCase())) ||
                    (this.displayedColumns.indexOf("Oppføringenliggerhos") != -1 && data.LiggerHos != null && data.LiggerHos.toLowerCase().includes(filter.toLowerCase())) ||
                    (this.displayedColumns.indexOf("Hovedstatus") != -1 && data.Hovedstatus != null && data.Hovedstatus.toLowerCase().includes(filter.toLowerCase())) ||
                    (this.displayedColumns.indexOf("Lokalstatus") != -1 && data.LokalStatus != null && data.LokalStatus.toLowerCase().includes(filter.toLowerCase())) ||
                    (this.displayedColumns.indexOf("Merknader") != -1 && merknader != null && merknader.includes(filter.toLowerCase()))

            };

            this.dataSource.filter = this.filter;

        }, fail => {
            this.modalService.Alert(fail.error.Message);
        });
    }

    hovedprosjektChange($event) {
        if (this.valgtHovedprosjektId != $event.value) {
            this.valgtHovedprosjektId = $event.value
            this.valgtHovedprosjektTittel = this.hovedprosjekter.find(h => h.id == this.valgtHovedprosjektId).text;
            this.oppdater();
        }
    }


    lastNedExcel() {
        const iframe = document.createElement("iframe");
        iframe.src = environment.APIUrl + "RapportOppforinger/GetRapportXlsx?prosjektId=" + this.valgtHovedprosjektId;
        iframe.style.display = "none";
        document.body.appendChild(iframe);
    }

    filterTimeout: any;
    delayedOppdaterFilter() {
        clearTimeout(this.filterTimeout);
        this.filterTimeout = setTimeout(() => {
            this.dataSource.filter = this.filter;
        }, 250);
    }
}
