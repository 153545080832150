<div class="RapportOppforinger view" [ng-busy]="busy">

    <div class="container-fluid slidein">


        <div class="panel panel-default scroll-panel">
            <div class="panel-heading">

                <h1 i18n="@@RapportOppforinger1">Oppføringer per hovedprosjekt </h1>
                <br />

                <div class="row form-group">
                    <div class="col-sm-4 col-lg-3">
                        <label i18n="@@RapportOppforinger2">Hovedprosjekt</label>
                        <select2 [options]="select2optionsSingle" [data]="hovedprosjekter" [value]="valgtHovedprosjektId" (valueChanged)="hovedprosjektChange($event); filter = null;"></select2>
                    </div>
                    <div class="col-sm-4 col-md-3 col-lg-2">
                        <label>&nbsp;</label><br />
                        <button type="button" class="btn btn-success" title="Eksporter" (click)="lastNedExcel()">
                            <span class="glyphicon glyphicon-export"></span>
                            <span i18n="@@RapportOppforinger3">Eksportér til Excel</span>
                        </button>
                    </div>
                    <div class="col-sm-4 col-lg-2">
                        <label>Søk..</label><br />
                        <div class="input-group">
                            <input class="form-control" [(ngModel)]="filter" (change)="delayedOppdaterFilter();" (input)="delayedOppdaterFilter();" (keypress)="delayedOppdaterFilter();" />
                            <span class="input-group-btn">
                                <button [disabled]="!filter" class="btn btn-primary" (click)="filter = null; dataSource.filter = null"><span class="glyphicon glyphicon-remove"></span></button>
                            </span>
                        </div>
                    </div>
                </div>
                <br />

                <h3 class="panel-title">
                    <span class="glyphicon glyphicon-list text-muted"></span>&nbsp;
                    <span i18n="@@RapportOppforinger4">Alle oppføringer</span> - <span>{{valgtHovedprosjektTittel}}</span>
                </h3>
            </div>

            <div id="TabellContainer" style="overflow: auto;">
                <table mat-table="" [dataSource]="dataSource" class="table-striped table-bordered">
                    <ng-container matColumnDef="Innsendt">
                        <th mat-header-cell="" *matHeaderCellDef=""><span i18n="@@RapportOppforinger29">Innsendt</span></th>
                        <td mat-cell="" *matCellDef="let item"><span [innerHTML]="item.Innsendt | date:&#39;dd.MM.yyyy HH:mm&#39;"></span></td>
                    </ng-container>

                    <ng-container matColumnDef="Hovedkontrakt">
                        <th mat-header-cell="" *matHeaderCellDef=""><span i18n="@@RapportOppforinger30">Hovedkontrakt</span></th>
                        <td mat-cell="" *matCellDef="let item"><span [innerHTML]="item.Hovedkontrakt | highlight : filter"></span></td>
                    </ng-container>

                    <ng-container matColumnDef="Oppføringsnummer">
                        <th mat-header-cell="" *matHeaderCellDef=""><span i18n="@@RapportOppforinger31">Oppføringsnummer</span></th>
                        <td mat-cell="" *matCellDef="let item"><span [innerHTML]="item.Oppforingsnr | highlight : filter"></span></td>
                    </ng-container>

                    <ng-container matColumnDef="Type">
                        <th mat-header-cell="" *matHeaderCellDef=""><span i18n="@@RapportOppforinger53">Type</span></th>
                        <td mat-cell="" *matCellDef="let item"><span [innerHTML]="item.Oppforingstype | highlight : filter"></span></td>
                    </ng-container>

                    <ng-container matColumnDef="Tittel">
                        <th mat-header-cell="" *matHeaderCellDef=""><span i18n="@@RapportOppforinger32">Tittel</span></th>
                        <td mat-cell="" *matCellDef="let item"><span [innerHTML]="item.Tittel | highlight : filter"></span></td>
                    </ng-container>

                    <ng-container matColumnDef="EtatEier">
                        <th mat-header-cell="" *matHeaderCellDef=""><span i18n="@@RapportOppforinger41">Etat / Eier</span></th>
                        <td mat-cell="" *matCellDef="let item"><span [innerHTML]="item.EtatEier | highlight : filter"></span></td>
                    </ng-container>

                    <ng-container matColumnDef="Krevetbeløp">
                        <th mat-header-cell="" *matHeaderCellDef=""><span i18n="@@RapportOppforinger42">Krevet beløp</span></th>
                        <td mat-cell="" *matCellDef="let item"><span [innerHTML]="item.KravBelop | number:&#39;1.0-0&#39;"></span></td>
                    </ng-container>

                    <ng-container matColumnDef="Antattbeløp">
                        <th mat-header-cell="" *matHeaderCellDef=""><span i18n="@@RapportOppforinger43">Antatt beløp</span></th>
                        <td mat-cell="" *matCellDef="let item"><span [innerHTML]="item.AntattBelop | number:&#39;1.0-0&#39;"></span></td>
                    </ng-container>

                    <ng-container matColumnDef="Omforentbeløp">
                        <th mat-header-cell="" *matHeaderCellDef=""><span i18n="@@RapportOppforinger44">Omforent beløp</span></th>
                        <td mat-cell="" *matCellDef="let item"><span [innerHTML]="item.OmforentBelop | number:&#39;1.0-0&#39;"></span></td>
                    </ng-container>

                    <ng-container matColumnDef="Fakturertbeløp">
                        <th mat-header-cell="" *matHeaderCellDef=""><span i18n="@@RapportOppforinger45">Fakturert beløp</span></th>
                        <td mat-cell="" *matCellDef="let item"><span [innerHTML]="item.FakturertBelop | number:&#39;1.0-0&#39;"></span></td>
                    </ng-container>

                    <ng-container matColumnDef="Opprinneligkrav">
                        <th mat-header-cell="" *matHeaderCellDef=""><span i18n="@@RapportOppforinger46">Opprinnelig krav</span></th>
                        <td mat-cell="" *matCellDef="let item"><span [innerHTML]="item.OpprinneligKravBelop | number:&#39;1.0-0&#39;"></span></td>
                    </ng-container>

                    <ng-container matColumnDef="Omtvistetbeløp">
                        <th mat-header-cell="" *matHeaderCellDef=""><span i18n="@@RapportOppforinger47">Omtvistet beløp</span></th>
                        <td mat-cell="" *matCellDef="let item"><span [innerHTML]="item.OmtvistetBelop | number:&#39;1.0-0&#39;"></span></td>
                    </ng-container>

                    <ng-container matColumnDef="Omforentuavklartbeløp">
                        <th mat-header-cell="" *matHeaderCellDef=""><span i18n="@@RapportOppforinger54">Omforent + uavklart beløp</span></th>
                        <td mat-cell="" *matCellDef="let item"><span [innerHTML]="item.OmforentUavklartBelop | number:&#39;1.0-0&#39;"></span></td>
                    </ng-container>

                    <ng-container matColumnDef="Frafaltbeløp">
                        <th mat-header-cell="" *matHeaderCellDef=""><span i18n="@@RapportOppforinger36">Frafalt beløp</span></th>
                        <td mat-cell="" *matCellDef="let item"><span [innerHTML]="item.FrafaltBelop | number:&#39;1.0-0&#39;"></span></td>
                    </ng-container>

                    <ng-container matColumnDef="UtgårfrakontraktGjøresopppåprosess">
                        <th mat-header-cell="" *matHeaderCellDef="">
                            <span i18n="@@app03RapportOppforinger1">Utgår fra kontrakt (+)</span>
                            <br />
                            <span i18n="@@app03RapportOppforinger2">Gjøres opp på prosess (-)</span>
                        </th>
                        <td mat-cell="" *matCellDef="let item"><span [innerHTML]="item.UtgarFraKontraktBelop | number:&#39;1.0-0&#39;"></span></td>
                    </ng-container>

                    <ng-container matColumnDef="Reellmerkostnad">
                        <th mat-header-cell="" *matHeaderCellDef=""><span i18n="@@RapportOppforinger60">Reell merkostnad</span></th>
                        <td mat-cell="" *matCellDef="let item"><span [innerHTML]="item.ReellMerkostnadBelop | number:&#39;1.0-0&#39;"></span></td>
                    </ng-container>

                    <ng-container matColumnDef="Avklartbådeøkonomiogtid">
                        <th mat-header-cell="" *matHeaderCellDef=""><span i18n="@@RapportOppforinger61">Avklart - både økonomi og tid</span></th>
                        <td mat-cell="" *matCellDef="let item"><span [innerHTML]="item.AvklartOkonomiOgTidBelop | number:&#39;1.0-0&#39;"></span></td>
                    </ng-container>

                    <ng-container matColumnDef="Fakturertdato">
                        <th mat-header-cell="" *matHeaderCellDef=""><span i18n="@@RapportOppforinger65">Fakturert dato</span></th>
                        <td mat-cell="" *matCellDef="let item"><span [innerHTML]="item.FakturertDato | date:&#39;dd.MM.yyyy&#39;"></span></td>
                    </ng-container>

                    <ng-container matColumnDef="AndreennBH">
                        <th mat-header-cell="" *matHeaderCellDef=""><span i18n="@@RapportOppforinger66">Andre enn BH</span></th>
                        <td mat-cell="" *matCellDef="let item"><span [innerHTML]="item.AndreEnnBH | highlight : filter"></span></td>
                    </ng-container>

                    <ng-container matColumnDef="Ferdigfakturert">
                        <th mat-header-cell="" *matHeaderCellDef=""><span i18n="@@RapportOppforinger67">Ferdig fakturert</span></th>
                        <td mat-cell="" *matCellDef="let item"><span [innerHTML]="item.FerdigFakturert | highlight : filter"></span></td>
                    </ng-container>

                    <ng-container matColumnDef="ReferansefraBH">
                        <th mat-header-cell="" *matHeaderCellDef=""><span i18n="@@RapportOppforinger68">Referanse fra BH</span></th>
                        <td mat-cell="" *matCellDef="let item"><span [innerHTML]="item.ReferanseFraBH | highlight : filter"></span></td>
                    </ng-container>

                    <ng-container matColumnDef="ReferanseUE">
                        <th mat-header-cell="" *matHeaderCellDef=""><span i18n="@@RapportOppforinger69">Referanse UE</span></th>
                        <td mat-cell="" *matCellDef="let item"><span [innerHTML]="item.ReferanseUE | highlight : filter"></span></td>
                    </ng-container>

                    <ng-container matColumnDef="Tilvalg">
                        <th mat-header-cell="" *matHeaderCellDef=""><span i18n="@@RapportOppforinger70">Tilvalg</span></th>
                        <td mat-cell="" *matCellDef="let item"><span [innerHTML]="item.Tilvalg | highlight : filter"></span></td>
                    </ng-container>

                    <ng-container matColumnDef="Godkjentbeløp">
                        <th mat-header-cell="" *matHeaderCellDef=""><span i18n="@@RapportOppforinger71">Godkjent beløp</span></th>
                        <td mat-cell="" *matCellDef="let item"><span [innerHTML]="item.GodkjentBelop | number:&#39;1.0-0&#39;"></span></td>
                    </ng-container>

                    <ng-container matColumnDef="Forventetgodkjentbeløp">
                        <th mat-header-cell="" *matHeaderCellDef=""><span i18n="@@RapportOppforinger73">Forventet godkjent beløp</span></th>
                        <td mat-cell="" *matCellDef="let item"><span [innerHTML]="item.ForventetGodkjentBelop | number:&#39;1.0-0&#39;"></span></td>
                    </ng-container>

                    <ng-container matColumnDef="Gjenståendekostnad">
                        <th mat-header-cell="" *matHeaderCellDef=""><span i18n="@@RapportOppforinger74">Gjenstående kostnad</span></th>
                        <td mat-cell="" *matCellDef="let item"><span [innerHTML]="item.GjenstaendeKostnad | number:&#39;1.0-0&#39;"></span></td>
                    </ng-container>

                    <ng-container matColumnDef="Fakturertgodkjent">
                        <th mat-header-cell="" *matHeaderCellDef=""><span i18n="@@RapportOppforinger75">Fakturert - godkjent</span></th>
                        <td mat-cell="" *matCellDef="let item"><span [innerHTML]="item.FakturertGodkjent | number:&#39;1.0-0&#39;"></span></td>
                    </ng-container>

                    <ng-container matColumnDef="UE">
                        <th mat-header-cell="" *matHeaderCellDef=""><span i18n="@@RapportOppforinger76">UE</span></th>
                        <td mat-cell="" *matCellDef="let item"><span [innerHTML]="item.UE | highlight : filter"></span></td>
                    </ng-container>

                    <ng-container matColumnDef="Krevetfristforlengelse">
                        <th mat-header-cell="" *matHeaderCellDef=""><span i18n="@@RapportOppforinger78">Krevet fristforlengelse</span></th>
                        <td mat-cell="" *matCellDef="let item"><span [innerHTML]="item.KrevetFristforlengelse | number:&#39;1.0-0&#39;"></span></td>
                    </ng-container>

                    <ng-container matColumnDef="Omforentfristforlengelse">
                        <th mat-header-cell="" *matHeaderCellDef=""><span i18n="@@RapportOppforinger79">Omforent fristforlengelse</span></th>
                        <td mat-cell="" *matCellDef="let item"><span [innerHTML]="item.OmforentFristforlengelse | number:&#39;1.0-0&#39;"></span></td>
                    </ng-container>

                    <ng-container matColumnDef="Sistemeldingsdato">
                        <th mat-header-cell="" *matHeaderCellDef=""><span i18n="@@RapportOppforinger48">Siste meldingsdato</span></th>
                        <td mat-cell="" *matCellDef="let item"><span [innerHTML]="item.SisteMeldingSendt | date:&#39;dd.MM.yyyy HH:mm&#39;"></span></td>
                    </ng-container>

                    <ng-container matColumnDef="Oppføringenliggerhos">
                        <th mat-header-cell="" *matHeaderCellDef=""><span i18n="@@RapportOppforinger49">Oppføringen ligger hos</span></th>
                        <td mat-cell="" *matCellDef="let item"><span [innerHTML]="item.LiggerHos | highlight : filter"></span></td>
                    </ng-container>

                    <ng-container matColumnDef="Hovedstatus">
                        <th mat-header-cell="" *matHeaderCellDef=""><span i18n="@@RapportOppforinger50">Hovedstatus</span></th>
                        <td mat-cell="" *matCellDef="let item"><span [innerHTML]="item.Hovedstatus | highlight : filter"></span></td>
                    </ng-container>

                    <ng-container matColumnDef="Lokalstatus">
                        <th mat-header-cell="" *matHeaderCellDef=""><span i18n="@@RapportOppforinger51">Lokal status</span></th>
                        <td mat-cell="" *matCellDef="let item"><span [innerHTML]="item.LokalStatus | highlight : filter"></span></td>
                    </ng-container>

                    <ng-container matColumnDef="Merknader">
                        <th mat-header-cell="" *matHeaderCellDef=""><span i18n="@@RapportOppforinger52">Merknader</span></th>
                        <td mat-cell="" *matCellDef="let item"><div [innerHTML]="item.Merknader | decodeHtml | highlight : filter"></div></td>
                    </ng-container>

                    <tr mat-header-row="" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row="" *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>

            <mat-paginator #paginator="" [pageSizeOptions]="[100, 250, 500]" showFirstLastButtons=""></mat-paginator>

        </div>
    </div>
</div>